@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?hqnugo');
  src:  url('icomoon.eot?hqnugo#iefix') format('embedded-opentype'),
    url('icomoon.ttf?hqnugo') format('truetype'),
    url('icomoon.woff?hqnugo') format('woff'),
    url('icomoon.svg?hqnugo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="moon-"], [class*=" moon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.moon-check-circle:before {
	content: "\e900";
  }
  .moon-loading:before {
	content: "\e901";
  }
  .moon-plus-button:before {
	content: "\e902";
  }
  .moon-buildings-alt-filled:before {
	content: "\e903";
  }
  .moon-buildings-filled:before {
	content: "\e904";
  }
  .moon-burger-bar-alt:before {
	content: "\e905";
  }
  .moon-calendar-filled:before {
	content: "\e906";
  }
  .moon-check-filled:before {
	content: "\e907";
  }
  .moon-check-alt-filled:before {
	content: "\e908";
  }
  .moon-check-empty:before {
	content: "\e909";
  }
  .moon-checklist-filled:before {
	content: "\e90a";
  }
  .moon-cross-filled:before {
	content: "\e90b";
  }
  .moon-cross:before {
	content: "\e90c";
  }
  .moon-danger-filled:before {
	content: "\e90d";
  }
  .moon-document-filled:before {
	content: "\e90e";
  }
  .moon-file-checked-filled:before {
	content: "\e90f";
  }
  .moon-file-remove-filled:before {
	content: "\e910";
  }
  .moon-filter-filled:before {
	content: "\e911";
  }
  .moon-home-filled:before {
	content: "\e912";
  }
  .moon-logout-filled:before {
	content: "\e913";
  }
  .moon-point-filled:before {
	content: "\e914";
  }
  .moon-document-text-filled:before {
	content: "\e915";
  }
  .moon-dots:before {
	content: "\e916";
  }
  .moon-pen-filled:before {
	content: "\e917";
  }
  .moon-pen-alt-filled:before {
	content: "\e918";
  }
  .moon-phone-filled:before {
	content: "\e919";
  }
  .moon-plane-filled:before {
	content: "\e91a";
  }
  .moon-play-filled:before {
	content: "\e91b";
  }
  .moon-plus:before {
	content: "\e91c";
  }
  .moon-map-point-filled:before {
	content: "\e91d";
  }
  .moon-settings-filled:before {
	content: "\e91e";
  }
  .moon-shield-check-filled:before {
	content: "\e91f";
  }
  .moon-shield-network-filled:before {
	content: "\e920";
  }
  .moon-arrow-down-filled:before {
	content: "\e921";
  }
  .moon-arrow-up-filled:before {
	content: "\e922";
  }
  .moon-bin-filled:before {
	content: "\e923";
  }
  .moon-dots-filled:before {
	content: "\e924";
  }
  .moon-user-filled:before {
	content: "\e925";
  }
  .moon-user-group-filled:before {
	content: "\e926";
  }
  .moon-arrow:before {
	content: "\e927";
  }
  .moon-buildings-alt:before {
	content: "\e928";
  }
  .moon-buildings:before {
	content: "\e929";
  }
  .moon-calendar:before {
	content: "\e92a";
  }
  .moon-checklist:before {
	content: "\e92b";
  }
  .moon-eye-closed:before {
	content: "\e92c";
  }
  .moon-eye:before {
	content: "\e92d";
  }
  .moon-filter:before {
	content: "\e92e";
  }
  .moon-burger-bar:before {
	content: "\e92f";
  }
  .moon-home:before {
	content: "\e930";
  }
  .moon-search:before {
	content: "\e931";
  }
  .moon-point:before {
	content: "\e932";
  }
  .moon-plane:before {
	content: "\e933";
  }
  .moon-map-point:before {
	content: "\e934";
  }
  .moon-bin:before {
	content: "\e935";
  }
  .moon-user-id:before {
	content: "\e936";
  }
  .moon-user:before {
	content: "\e937";
  }
  .moon-user-group:before {
	content: "\e938";
  }
  .moon-clipboard-checked:before {
	content: "\e939";
  }
  .moon-clipboard-list:before {
	content: "\e93a";
  }
  .moon-clipboard-remove:before {
	content: "\e93b";
  }
  .moon-clipboard-checked-filled:before {
	content: "\e93c";
  }
  .moon-clipboard-list-filled:before {
	content: "\e93d";
  }
  .moon-clipboard-remove-filled:before {
	content: "\e93e";
  }
  .moon-user-block-filled:before {
	content: "\e93f";
  }
