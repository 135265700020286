// Dimensions
$appMaxWidth: 1800px;
$borderRadius: 4px;

// Colors
$primaryColor: #223450;
$secondaryColor: #EAA22E;
$tertiaryColor: #4960A2;
$fourthColor: #359992;
$green: #2e7d32;
$green050: #d5ffff;
$green100: #c0f7f7;
$green150: #aaeeee;
$green200: #95e6e6;
$green250: #80ddde;
$green300: #55cdcd;
$green400: #2bbcbd;
$green500: #00abac;
$green600: #00898a;
$green700: #006767;
$green800: #004445;
$green850: #003334;
$green900: #002222;
$green950: #001111;
$black: #000000;
$black050: #2d2c2c;
$white: #ffffff;
$gray: #757575;
$gray100: #3c3c3c;
$gray200: #666666;
$gray300: #939393;
$gray500: #bdbdbd;
$gray600: #d9d9d9;
$gray700: #eaeaea;
$gray800: #f5f5f5;
$red: #d32f2f;
$yellow: #f5c108;

$input-border-color: #d9d9d9;

$alertWarningBackgroundColor: #fbe69c;
$alertInfoBackgroundColor: #c5e1f9;
$alertDangerBackgroundColor: #f3bdbd;
$alertSuccessBackgroundColor: #c5f9c5;

// Fonts
@font-face {
	font-family: "Noto Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-Regular.ttf");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Noto Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Noto Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-SemiBold.ttf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Noto Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-Bold.ttf");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Noto Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-ExtraBold.ttf");
	font-weight: 800;
	font-style: normal;
}

/* RESET CSS */
/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

html,
body,
#root {
	height: 100%;
	font-size: 16px;
	position: relative;

	h1 {
		font-size: 2.25rem;
	}

	h2 {
		font-size: 2.15rem;
	}

	h3 {
		font-size: 2rem;
	}

	h4 {
		font-size: 1.75rem;
	}

	h5 {
		font-size: 1.5rem;
	}

	h6 {
		font-size: 1.25rem;
		font-weight: 300;
	}
}

/* Set core body defaults */
body {
	background-color: var(--white);
	line-height: 1.5;
	color: var(--primary-color);

	* {
		text-rendering: optimizeSpeed;
		font-family: "Noto Sans", sans-serif;
	}
}

.tj-app__main {
	display: flex;
	flex: 1;
	overflow: hidden;
}

.tj-app__content {
	flex: 1;
}

.tj-app__mobile,
.tj-app__desktop {
	overflow: hidden auto;
}

.tj-app__mobile {
	padding-bottom: 6.5rem;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
	max-width: 100%;
	display: block;
	pointer-events: none;
	user-select: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: "Noto Sans", sans-serif;
	border-radius: var(--input-border-radius);
}

.tj-app {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

:root {
	--app-max-width: #{$appMaxWidth};
	--border-radius: #{$borderRadius};

	--primary-color: #{$primaryColor};
	--secondary-color: #{$secondaryColor};
	--tertiary-color: #{$tertiaryColor};
	--fourth-color: #{$fourthColor};
	--white: #{$white};
	--red: #{$red};
	--yellow: #{$yellow};
	--green: #{$green};
	--green-100: #{$green100};
	--green-150: #{$green150};
	--green-200: #{$green200};
	--green-250: #{$green250};
	--green-300: #{$green300};
	--green-400: #{$green400};
	--green-500: #{$green500};
	--green-600: #{$green600};
	--green-700: #{$green700};
	--green-800: #{$green800};
	--green-850: #{$green850};
	--green-900: #{$green900};
	--green-950: #{$green950};
	--black: #{$black};
	--black-050: #{$black050};
	--gray: #{$gray};
	--gray-100: #{$gray100};
	--gray-200: #{$gray200};
	--gray-300: #{$gray300};
	--gray-500: #{$gray500};
	--gray-600: #{$gray600};
	--gray-700: #{$gray700};
	--gray-800: #{$gray800};
	--alert-warning-background-color: #{$alertWarningBackgroundColor};
	--alert-info-background-color: #{$alertInfoBackgroundColor};
	--alert-danger-background-color: #{$alertDangerBackgroundColor};
	--alert-success-background-color: #{$alertSuccessBackgroundColor};
}

.mat-form-field-appearance-fill.tj-fill-container,
.mat-form-field-appearance-fill.tj-fill-container .mat-form-field-appearance-fill-infix,
.mat-form-field-appearance-fill.tj-fill-container .mat-form-field-appearance-fill-flex,
.mat-form-field-appearance-fill.tj-fill-container .mat-form-field-appearance-fill-wrapper,
.mat-form-field-appearance-fill textarea {
	height: 100%;
	width: 100%;
}

.mdc-text-field--filled,
.mat-mdc-form-field-focus-overlay {
	background-color: transparent !important;
	padding: 0;
}

.mat-mdc-form-field-error-wrapper {
	padding: 0 !important;
}

.mdc-circular-progress__circle-clipper {
	width: 100% !important;

	svg {
		stroke: #fff !important;
	}
}

.tj-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
	min-height: 36px;
	padding: 5px 20px;
	font-size: 1rem;
	background: var(--white);
	border: 1px solid var(--gray-600);
	border-radius: var(--border-radius);
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	&:hover:not(:disabled):not(.tj-btn__success):not(.tj-btn__danger):not(.tj-btn__warning):not(.tj-btn__primary) {
		background: var(--gray-800);
	}

	&.tj-btn__success {
		background: var(--green);
		border-color: var(--green);

		* {
			color: var(--white);
		}

		&:hover:not(:disabled) {
			background: lighten($green, 10%);
			border-color: lighten($green, 10%);
		}
	}

	&.tj-btn__danger {
		background: var(--red);
		border-color: var(--red);

		* {
			color: var(--white);
		}

		&:hover:not(:disabled) {
			background: lighten($red, 10%);
			border-color: lighten($red, 10%);
		}
	}

	&.tj-btn__warning {
		background: var(--yellow);
		border-color: var(--yellow);

		* {
			color: var(--white);
		}

		&:hover:not(:disabled) {
			background: lighten($yellow, 10%);
			border-color: lighten($yellow, 10%);
		}
	}

	&.tj-btn__primary {
		background: var(--primary-color);
		border-color: var(--primary-color);

		* {
			color: var(--white);
		}

		&:hover:not(:disabled) {
			background: lighten($primaryColor, 10%);
			border-color: lighten($primaryColor, 10%);
		}
	}

	&.tj-btn__invisible {
		border: none;
		outline: none;
		background: none;
		padding: 0 5px;
		margin: 0 -5px 0 0;
		cursor: pointer;

		&--tree {
			@extend .tj-btn__invisible;
			min-height: 25px;
		}

		&:hover:not(:disabled) {
			background: none !important;
		}
	}

	&.tj-btn__icon {
		color: $gray200;
		border: 1px solid $gray200;
		border-radius: 0.5rem;
	}

	&:disabled {
		background: var(--gray-500);
		border-color: var(--gray-500);
		cursor: not-allowed;
	}

}

.tj-alert {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 5px 15px;
	border-radius: var(--border-radius);
	background-color: var(--white);

	i {
		font-size: 1.25rem;
	}

	span {
		&:first-of-type {
			font-weight: 600;
		}

		&:last-of-type {
			font-size: 0.875rem;
		}
	}

	&.tj-alert__success {
		background-color: var(--alert-success-background-color);

		i {
			color: #2e7d32;
		}
	}

	&.tj-alert__danger {
		background-color: var(--alert-danger-background-color);

		i {
			color: #d32f2f;
		}
	}

	&.tj-alert__warning {
		background-color: var(--alert-warning-background-color);

		i {
			color: #f5c108;
		}
	}

	&.tj-alert__info {
		background-color: var(--alert-info-background-color);

		i {
			color: #359992;
		}
	}

	&.tj-alert__fullwidth {
		width: 100%;
	}

	.tj-alert__mobile {
		display: flex;
		flex-direction: column;
	}

	.tj-alert__desktop {
		display: flex;
		gap: 10px;
	}
}

.mdc-tab__content {
	font-size: 1rem;
}

.mdc-tab-indicator--active {
	.mdc-tab__text-label {
		font-weight: 600;
	}
}

.tj-hidden {
	visibility: 0;
}

.tj-accordion {
	box-shadow: 0px 0px 10px 0px #0101011A;
	padding: 2px;

	.moon-arrow-down-filled {
		color: var(--gray-500);
	}
}

.tj-rotate-90 {
	transform: rotate(90deg);
}

.tj-rotate-180 {
	transform: rotate(180deg);
}

.mat-content-hide-toggle {
	margin-right: 0 !important;
}

.tj-work-system .mat-mdc-form-field-infix,
.tj-specific-characteristics .mat-mdc-form-field-infix,
.tj-risks-evaluated .mat-mdc-form-field-infix,
.tj-worksite .mat-mdc-form-field-infix,
.tj-personal-measures .mat-mdc-form-field-infix,
.tj-additional-information .mat-mdc-form-field-infix {
	padding: 5px 0 5px !important;
	min-height: 30px;
}

.mat-mdc-text-field-wrapper {
	padding: 0 !important;
}

.tj-color__primary {
	color: var(--primary-color) !important;
}

.tj-color__gray-300 {
	color: var(--gray-300);
}

.tj-drawer-select {
	width: 100%;

	.mat-mdc-form-field-infix {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
		min-height: 30px;
	}
}

.tj-list-item {
	border-bottom: 2px solid var(--gray-700);

	.tj-list-item__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 0;

		.tj-item-name {
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 0.875rem;
			color: var(--gray-200);
			padding: 0;
			margin: 0;

			&.ml-1 {
				margin-left: 3rem;
			}

			i {
				font-size: 1.5rem;
				color: var(--primary-color);
			}
		}

		i {
			font-size: 1.5rem;
			color: red;
			cursor: pointer
		}
	}

	.tj-list-item__actions {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 1rem;
	}

	.tj-list-item__children {
		.tj-list-item__item {
			border-bottom: 2px solid var(--gray-700);
		}

		.tj-list-item__item:last-child {
			border-bottom: none;
		}
	}
}

.tj-search-bar {
	color: var(--primary-color);

	.tj-search-icon {
		display: block;
		font-size: 0.75rem;
	}

	.mat-mdc-form-field-infix {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
		min-height: 30px;
	}

	button {
		background: transparent;
		border: none;
		outline: none;
		box-shadow: none;
		cursor: pointer;

		i {
			font-size: 1.25rem;
		}
	}

	.tj-search-bar {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		input {
			border: none;
			outline: none;
			box-shadow: none;
			background: transparent;

			color: inherit;
			font-size: 1rem;
		}
	}
}

.mat-expansion-panel-header-description {
	flex-grow: unset !important;
}

.tj-drawer-input {
	width: 100%;
	margin-bottom: 10px;
}

// VISIT COMPONENTS

.tj-risk-description-modal,
.tj-risk-hazard-modal,
.tj-send-gfb-modal {
	.mat-mdc-form-field-infix {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
		min-height: 30px;
	}
}

.tj-risk-hazard-modal,
.tj-send-gfb-modal {
	.mat-mdc-select-value-text {
		.mat-mdc-select-min-line {
			font-size: 1rem;
			font-weight: 600;
			color: var(--primary-color) !important;
		}
	}
}

.tj-risks-evaluated-tree,
.tj-preventions-evaluated-tree {
	.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
		background-color: var(--primary-color) !important;
		border-color: var(--primary-color) !important;

		&:after {
			background-color: var(--primary-color) !important;
		}
	}

	.ant-tree-checkbox.ng-star-inserted.ant-tree-checkbox-indeterminate {
		.ant-tree-checkbox-inner {

			&:after {
				background-color: var(--primary-color) !important;
			}
		}
	}

	.ant-tree-treenode-switcher-open,
	.ant-tree-treenode-switcher-close {
		.ant-tree-checkbox {
			display: none !important;
		}

		.ant-tree-title {
			color: black !important;
		}

		.ant-tree-checkbox-inner {
			display: none !important;
		}
	}

}


.mdc-checkbox {
	.mdc-checkbox__ripple {
		display: none !important;
	}

	.mdc-checkbox__native-control.mdc-checkbox--selected~.mdc-checkbox__background {
		border-color: var(--primary-color) !important;
		background-color: var(--primary-color) !important;
	}

	.mdc-checkbox__native-contro.mdc-checkbox--selected~.mdc-checkbox__ripple,
	.mdc-checkbox__native-contro.mdc-checkbox--selected~.mat-ripple.mat-mdc-checkbox-ripple.mat-mdc-focus-indicator {
		display: none !important;
	}
}

.cdk-overlay-container,
.cdk-overlay-pane {
	z-index: 9999 !important;
}

.tj-accordion-input {
	textarea {
		resize: none !important;
		max-height: 96px;
	}
}

.tj-work-system .tj-btn,
.tj-specific-characteristics .tj-btn,
.tj-risks-evaluated .tj-btn,
.tj-worksite .tj-btn,
.tj-personal-measures .tj-btn,
.tj-staff,
.tj-prevention .tj-btn,
.tj-additional-information {
	i {
		font-size: 1.75rem !important;
	}
}

.tj-drawer-user {
	display: flex;
	align-items: center;
	gap: 5px;

	.tj-drawer-user__avatar {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
		border-radius: 50%;
		background-color: var(--gray-500);
		color: var(--white);

		span {
			font-size: 0.75rem;
		}
	}

	.tj-drawer-user__name {
		font-size: 0.875rem;
		font-weight: 500;
	}
}

.tj-adjust-bottom {
	margin-bottom: 10px;
}

.mdc-tab__text-label {
	gap: 5px;

	i {
		font-size: 1.5rem;
	}
}

.tj-table-no-data {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 100%;
	font-size: 1.25rem;
	color: var(--gray-300);

	p {
		margin: 0;
	}
}